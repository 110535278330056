
<template>
  <div class="wrapper">
    <div class="baidu-map-echart" id="container"></div>
    <div class="operateWrapper">
      
      <el-input
        v-model="searchAdress.text"
        size="large"
        placeholder="查询地址"
      />
      <el-button type="primary" class="searchBtn" @click="searchAction">搜索</el-button>

      <!-- <div :class="{'boxSelect': true, 'boxActive': reactData.openDraw }" @click="toggleDraw">
        <div class="box" />
      </div> -->
    </div>
  </div>
</template>
<script>
// import red from '@/assets/img/red.png'
import green from '@/assets/img/green.png'
import yellow from '@/assets/img/yellow.png'
import BMap from 'BMap';
import { onMounted, reactive } from "vue";
import { gisinfo } from "@/request/api";
export default {
  setup() {
    const searchAdress = reactive({
      text:''
    })
    //标点的数据格式，其中value是必须的[经度，纬度，值]
    
    const state = reactive({
      color: "",
      
      transfer: [
      
      ],
      vehicle: [
       
      ],
    });
    // 充电站信息
    const getList=()=>{
      const dataa = {
        operator_id : sessionStorage.getItem('userID')
      }
      gisinfo(dataa).then((res)=>{
        if (res) {
          console.log(res,'充电站gis');
          if (res.code == 200) {
            state.transfer = res.data
          } else {
            ElMessage.error(res.msg);
          }
          addTransferMarker()
        } else {
          ElMessage.error("数据获取失败");
        }
      })
      // gis_station().then((res)=>{
      //   if (res) {
      //     // data.loading = false
      //     if (res.code == 200) {
      //       state.transfer = res.data
      //       console.log(state.transfer,'state.transfer');
      //     } else {
      //       ElMessage.error(res.msg);
      //     }
      //     addTransferMarker()
      //   } else {
      //     ElMessage.error("数据获取失败");
      //   }
        
      // })
    }
    // 初始化地图
    let myMap = null;
    function initMapGL() {
      const centerPoint = new BMap.Point(112.44383667724607,34.62428172135043);
      myMap = new BMap.Map("container");
      myMap.centerAndZoom(centerPoint, 10);
      myMap.setMinZoom(10); // 级别越大看的越详细
      myMap.setMaxZoom(25);
      myMap.enableScrollWheelZoom(true);
   
      // 右上角放大缩小控件
    //   var position = {
    //     anchor:BMAP_ANCHOR_TOP_RIGHT
    // };
    // myMap.addControl(new BMap.NavigationControl(position));
  }
    // 绘制标识物 
    function addTransferMarker() {
      state.transfer.forEach((item) => {
        if(item.type_id == 2){
          state.color = green;
        }else if(item.type_id == 4){
          state.color = yellow;
        }
        item.state2 = '正常'
        // item.img="https://n.sinaimg.cn/sinakd20220501s/599/w800h599/20220501/964e-801abd64b2c994a3c11acded4190d922.jpg"
        // if(indexEvery){
        //   state.color = green;
        //   item.state2 = '正常'
        // } else {
        //   state.color = red;
        //   item.state2 = '异常'
        // }
        const transIcon = new BMap.Icon(state.color, new BMap.Size(30,30))
        // 标识物
        const marker = new BMap.Marker(new BMap.Point(item.cord_j, item.cord_w), { icon: transIcon });
        myMap.addOverlay(marker);
        // 标注
        const opts = {
          position: new BMap.Point(item.cord_j, item.cord_w), // 指定文本标注所在的地理位置
          offset: new BMap.Size(20, 10) // 设置文本偏移量
        };

        const label = new BMap.Label([item.station_name,item.state2], opts); // 创建文本标注对象
        label.setStyle({
          lineHeight: '20px',
          borderColor: '#aaa',
          padding: '0 4px',
          backgroundColor: 'white'
        })
        myMap.addOverlay(label); 

        // 信息弹窗
        const content = `
          <div
            style="
              width: 280px;
              height: 200px;
              border: 5px solid yellow;
              border-radius: 20px;
              background-color: #fff;
              position: relative;
              overflow: hidden;
            "
          >
            
            <span style="display:block; background-color:rgb(0,153,255); height:40px; line-height:40px; font-size:18px; text-align:center; color:#fff">${item.station_name}</span>
            <div 
              style="
                margin: 15px 30px;
              "
            >
              <div
                style="
                  margin-top: 15px;
                  color: #333;
                  line-height: 20px;
                  font-weight: 600;
                "
              >
            

                
                <span>充电站类型：${item.type_id}轮充电站</span>
                <br/>
                <span>充电站位置：${item.province}${item.city}${item.county}</span>
                <br/>
                <span>充电桩数量：${item.count}个</span>
                <br/>
                <span>使用中：${item.charge}个</span>
                <br/>
                <span>空闲设备：${item.state_kx}个</span>
                <br/>
               <span>异常设备：${item.state_yc}个</span>
                <br/>
                <span>功率：${item.power}千瓦</span>
                <br/>
              </div>
              
              
              </div>

                  
                  <img/>
                  


         
            </div>
        `
        const infoWindow = new BMap.InfoWindow(content, { // 创建信息窗口对象
          setWidth : 280,     // 信息窗口宽度
          setHeight: 390,     // 信息窗口高度
            // title : item.name, // 信息窗口标题
        })
        marker.addEventListener("mouseover", function() { //开启信息窗口   
          myMap.openInfoWindow(infoWindow, opts.position); 
        });
        marker.addEventListener("mouseout", function() { //关闭信息窗口    
          myMap.closeInfoWindow();
        });
      //   document.getElementById('imgDemo').onload = function () {
      //     infoWindow.redraw()
      // };
      })
    }
    
    function setZoom(bPoints) {
      const view = myMap.getViewport(eval(bPoints));
			var mapZoom = view.zoom; 
			var centerPoint = view.center; 
			myMap.centerAndZoom(centerPoint,mapZoom);
    }

    // 中文搜索定位
    function searchAction() {
      if (searchAdress.text === '') return
      const addressName = `${searchAdress.text}`;
      // myMap = new BMap.Map("container");
      const local = new BMap.LocalSearch(myMap, {  
        renderOptions:{
          map:myMap
        },   
        
      });
      
      local.search(addressName);

      searchAdress.text=""
      // 点击地图时关闭信息窗口
      myMap.enableCloseOnClick()
    }
//   
 
     

  
    onMounted(() => {
      
      getList() // 获取充电站信息
      initMapGL(); // 生成地图
  
       // 添加标识物
      // addCarMarker();
      // search_place();
      // show_addr_rearch()
      // initDrawingManager(); // 框选
      // nearArea(new BMap.Point(112.59592, 34.906981));
      // nearAearContent(new BMap.Point(112.59592, 34.906981));
      // setTimeout(updateVehicleDate, 100); // 动态轨迹
    });
    return {
      // timer,
      state,
      // reactData,
      // circleData,
      searchAdress,
      // toggleDraw,
      setZoom,
      searchAction,
      getList
      
    };
  }
};
</script>
<style scoped>
  .wrapper {
    height: 100%;
    position: relative;
  }
  .baidu-map-echart {
    height: 100%;
  }
  .operateWrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
  }
  .searchBtn {
    margin-left: 10px;
    /* margin-top: 5px; */
    width: 80px;
    height: 40px;
  }

</style>
